import { Box, IconButton } from '@mui/material';
import {
  CloseOutlined,
  FormatListBulletedOutlined,
  SettingsOutlined,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppURLs } from 'app/utils/appURLs';
import { Module } from './types';
import { firebaseAuth } from 'App';
import { firebaseSignOut } from 'app/utils/firebase';
import { getLoggedInPartnerLogo } from 'app/utils/logo';
import maxHomeLogo from 'assets/logos/maxhome.svg';
import { sendPasswordResetEmail } from 'firebase/auth';
import { toast } from 'react-toastify';
import { Popconfirm } from 'antd';

const iconStyle: React.CSSProperties = {
  fontSize: '2rem',
  cursor: 'pointer',
  color: '#2F78EB',
  borderRadius: '10px',
  margin: '0.5rem',
};

const logoStyleFull: React.CSSProperties = {
  maxWidth: '6rem',
  maxHeight: '2.5rem',
};

const logout = async () => {
  await firebaseSignOut();
  window.open(AppURLs.agentLogin(), '_self');
};

export const OfficeAdminNavbar = ({ onClick = null }: { onClick?: any }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialModules: Module[] = [
    {
      name: 'Overview',
      icon: FormatListBulletedOutlined,
      path: AppURLs.officeAdminChecks(),
      alignment: 'normal',
    },
    {
      name: 'Settings',
      icon: SettingsOutlined,
      path: '',
      alignment: 'bottom',
      children: [
        {
          name: 'Change Password',
          onClick: () => {
            sendPasswordResetEmail(
              firebaseAuth,
              JSON.parse(localStorage.getItem('email') || '')
            )
              .then(() => {
                toast.success(
                  'Password reset email sent! Please check your inbox.'
                );
              })
              .catch((error) => {
                toast.error('Error: ' + error.message);
              });
          },
        },
        {
          name: 'Switch Roles',
          onClick: () => {
            setSwitchRolesOpen(!switchRolesOpen);
          },
          children: [], // To be populated dynamically
        },
        {
          name: 'Logout',
          onClick: () => {
            logout();
          },
        },
      ],
    },
  ];

  const [modules, setModules] = useState(initialModules);
  const [selectedModule, setSelectedModule] = useState<number | null>(-1);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [switchRolesOpen, setSwitchRolesOpen] = useState(false);

  const onClickModule = (index: number) => {
    const selected = modules[index];
    if (selected.name === 'Settings') {
      setSettingsOpen(!settingsOpen);
    } else {
      setSelectedModule(index);
      onClick && onClick();
      navigate(selected.path);
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    let moduleIndex = -1;

    modules.forEach((module, index) => {
      if (module.path && currentPath.includes(module.path)) {
        moduleIndex = index;
      }
    });

    if (moduleIndex !== -1 && modules[moduleIndex].name !== 'Settings') {
      setSelectedModule(moduleIndex);
    }

    // Load profiles for Switch Roles
    const profiles = JSON.parse(localStorage.getItem('profiles') || '[]');
    setModules((prevModules) =>
      prevModules.map((module) => {
        if (module.name === 'Settings') {
          return {
            ...module,
            children: module.children?.map((child: any) => {
              if (child.name === 'Switch Roles') {
                return {
                  ...child,
                  children: profiles
                    .filter(
                      (profile: any) =>
                        profile?.role !== 'Admin' &&
                        profile?.role !== 'ComplianceAdmin'
                    )
                    .map((profile: any) => ({
                      name: profile?.roleName,
                      onClick: () => {
                        if (profile.role === 'Agent') {
                          window.open(
                            AppURLs.agentDashboardMainHome(),
                            '_self'
                          );
                        } else if (profile.role === 'Compliance') {
                          window.open(
                            AppURLs.complianceDashboardChecks(),
                            '_self'
                          );
                        } else if (profile.role === 'OfficeAdmin') {
                          window.open(AppURLs.officeAdminChecks(), '_self');
                        }
                      },
                    })),
                };
              }
              return child;
            }),
          };
        }
        return module;
      })
    );
  }, [location]);

  return (
    <>
      <Box
        padding="1rem"
        height="100%"
        width="100%"
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          boxSizing: 'border-box',
        }}
      >
        <Box display="flex" marginBottom="1.5rem">
          <img
            src={getLoggedInPartnerLogo('OfficeAdmin')}
            alt="Partner Logo"
            style={logoStyleFull}
          />
          <img
            src={maxHomeLogo}
            alt="MaxHome Logo"
            style={{
              ...logoStyleFull,
              marginLeft: '0.5rem',
              paddingLeft: '0.5rem',
              borderLeft: '1px solid black',
            }}
          />

          {onClick && (
            <IconButton sx={{ marginLeft: 'auto' }} onClick={() => onClick()}>
              <CloseOutlined />
            </IconButton>
          )}
        </Box>

        {modules &&
          modules.map((module, index) => {
            const Icon = module.icon;
            return (
              <Box
                display="flex"
                flexDirection="column"
                key={index}
                marginBottom={module.name === 'Settings' ? '0.5rem' : ''}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  fontSize="1rem"
                  fontWeight={550}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: `${
                      index === selectedModule ? '#F6F6F6' : ''
                    }`,
                  }}
                  borderRadius="16px"
                  marginTop={module.alignment === 'normal' ? 0 : 'auto'}
                  onClick={() => onClickModule(index)}
                >
                  <Icon style={{ ...iconStyle }} /> {module.name}
                </Box>

                {module.name === 'Settings' && settingsOpen && (
                  <Box marginLeft="1rem" display="flex" flexDirection="column">
                    {module.children?.map((child, childIndex) => (
                      <Box
                        key={childIndex}
                        sx={{
                          cursor: 'pointer',
                          padding: '0.5rem 0',
                        }}
                      >
                        {child.name === 'Logout' ||
                        child.name === 'Change Password' ? (
                          <Popconfirm
                            title={child.name}
                            description={`Are you sure you want to ${child.name.toLowerCase()}?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={child.onClick}
                          >
                            <span>{child.name}</span>
                          </Popconfirm>
                        ) : (
                          <span onClick={child.onClick}>{child.name}</span>
                        )}
                        {child.name === 'Switch Roles' && switchRolesOpen && (
                          <Box
                            marginLeft="2rem"
                            display="flex"
                            flexDirection="column"
                          >
                            {child.children?.map((role, roleIndex) => (
                              <Box
                                key={roleIndex}
                                sx={{
                                  cursor: 'pointer',
                                  padding: '0.5rem 0',
                                }}
                                onClick={role.onClick}
                              >
                                {role.name}
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default OfficeAdminNavbar;
